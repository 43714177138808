<template>
  <article class="card"
           v-if="card.variants && card.variants.length"
           :class="{_constructor: isConstructor}">
    <div class="card__inner">

      <router-link
          :to="computedLink"
          class="card__img-wrap">
        <div class="card__labels"
             v-if="card.badges && card.badges.length">
          <BaseLabel v-for="item in card.badges"
                     :key="item.id"
                     class="card__labels-item"
                     :label="item"/>
        </div>
        <img :src="$imgPlaceholder"
             class="lazyload"
             :data-src="card.image_xs"
             :alt="card.name">
      </router-link>
      <div class="card__content">
        <h4 class="card__title">
          <router-link :to="computedLink">{{ card.name }}</router-link>
        </h4>
        <div class="card__subs  text" v-if="card.description">
          <p>{{ stripHtml(card.description) }}</p>
        </div>
        <div class="card__price-wrap price">
          <BaseSelect v-if="card.sauces && card.sauces.length"
                      v-model="propertySelected"
                      :options="card.sauces"
                      textKey="name"
                      :label="$t('Соус')"
                      class="card__select _small"
          />
          <ProductSize v-if="card.variants && card.variants.length > 1"
                       :variants="card.variants"
                       :selected="selectedVariant"
                       @selectVariant="onSelectVariant"
                       class="price__size"/>

          <span class="price__val" v-if="categoryDiscountValue">
            <i class="price__val-new">{{ (getPrice - categoryDiscountValue) * quantity | formatPrice }} {{ $t('грн') }}</i>
            <i class="price__val-old">{{ getPrice * quantity | formatPrice }} {{ $t('грн') }}</i>
          </span>
          <span class="price__val" v-else>{{ getPrice * quantity | formatPrice }} {{ $t('грн') }}</span>
          <span class="price__val _weight" v-if="selectedVariant.weight">{{ selectedVariant.weight }}</span>
        </div>
      </div>
      <div class="card__footer">
        <router-link v-if="isConstructor"
                     :to="computedLink"
                     class="card__btn base-btn _black"
        >
          {{ $t('Зібрати піцу') }}
        </router-link>
        <template v-else>
          <Quantity class="card__quantity"
                    v-model="quantity"/>
          <button type="button"
                  class="card__btn base-btn"
                  @click="addToBasket"
                  :title="`${$t('Замовити')} ${card.name}`">
            {{ $t('Замовити') }}
          </button>
        </template>
      </div>
      <AddToBasket :quantity="quantity" ref="add-el"/>
    </div>
  </article>
</template>

<script>
  import {mapActions} from 'vuex'
  import Quantity from './Quantity'
  import stripHtml from '@/helpers/stripHtml'
  import {addToBasketAnimation} from '@/helpers/addToBasketAnimation'
  import AddToBasket from '@/components/AddToBasket'
  import ProductSize from '@/components/ProductSize'
  import getCategoryDiscountPercent from '@/helpers/getCategoryDiscountPercent'
  import getDiscountValue from '@/helpers/getDiscountValue'

  export default {
    name: 'Card',
    components: {ProductSize, AddToBasket, Quantity},
    props: {
      card: {
        type: Object,
        required: true
      },
      isConstructor: {
        type: Boolean
      }
    },
    data() {
      return {
        stripHtml,
        selectedVariant: null,
        quantity: 1,
        propertySelected: null
      }
    },
    computed: {
      computedLink() {
        return {
          name: 'product',
          params: {
            id: this.isConstructor ? 'constructor' : this.card.slug ? this.card.slug : this.card.id
          }
        }
      },
      getPrice() {
        return this.selectedVariant && this.selectedVariant.price || 0
      },
      categoryDiscount() {
        return getCategoryDiscountPercent(this.card.category)
      },
      categoryDiscountValue() {
        return getDiscountValue(this.getPrice, this.categoryDiscount)
      }
    },
    methods: {
      ...mapActions('basket', ['addProductToBasket']),
      onSelectVariant(variant) {
        this.selectedVariant = variant
      },
      setDefault() {
        if (this.card.sauces && this.card.sauces.length) {
          this.propertySelected = this.card.sauces[0]
        }
        if (this.card.variants && this.card.variants.length) {
          this.onSelectVariant(this.card.variants[0])
        }
      },
      addToBasket(event) {
        const cardObj = {
          product: JSON.parse(JSON.stringify(this.card)),
          quantity: this.quantity,
          variant: this.selectedVariant || null,
          property: this.propertySelected || null,
          additional_ingredients: null,
          comment: null,
        }

        addToBasketAnimation(event, this.$refs['add-el'].$el, () => {
          this.addProductToBasket(cardObj)
        })

      }
    },
    created() {
      this.setDefault()
    }
  }
</script>

<style>

</style>
