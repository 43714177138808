<template>
  <div class="product-size">
    <button type="button"
            v-for="(item, index) in variants"
            :key="index"
            class="product-size__value"
            :class="{'_active': selected === item}"
            @click="$emit('selectVariant', item)"
    >{{ item.name }}</button>
  </div>
</template>

<script>
  export default {
    props: {
      variants: null,
      selected: null
    }
  }
</script>
