<template>
  <div class="popular-block container">
    <div class="popular-block__inner container-inner">
      <div class="popular-block__head">
        <h3 class="popular-block__title">{{ title }}</h3>
        <div
            class="popular-block__nav-wrap"
            :class="{_disabled: swiperIsDisabled}"
        >
          <div class="popular-block__pagination" ref="pagination"></div>
          <div class="popular-block__navs carousel-navs">
            <button
                ref="slide-prev"
                type="button"
                class="carousel-navs__btn _prev"
            >
              <BaseSvg name="ico-arrow-long"/>
            </button>
            <button
                ref="slide-next"
                type="button"
                class="carousel-navs__btn _next"
            >
              <BaseSvg name="ico-arrow-long"/>
            </button>
          </div>
        </div>
      </div>
      <div
          ref="carousel"
          class="popular-block__wrap"
          :class="pending ? '_loading' : null"
      >
        <BasePreloader v-if="pending"/>
        <div class="popular-block__list">
          <Card v-for="item in products"
                :key="item.id"
                :card="item"
                class="popular-block__item"
          />
        </div>
        <div class="swiper-scrollbar" ref="scrollbar"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import Card from './Card'
  import Swiper, {Navigation, Pagination, Scrollbar, Autoplay} from 'swiper/core'
  import {mapActions} from 'vuex'

  Swiper.use([Navigation, Pagination, Scrollbar, Autoplay])

  export default {
    name: 'PopularBlock',
    components: {Card},
    props: {
      title: {
        type: String,
      },
      pending: {
        type: Boolean,
      },
      products: {
        type: Array,
      },
    },
    data() {
      return {
        swiper: null
      }
    },
    computed: {
      swiperIsDisabled() {
        if (!this.swiper) return true
        return this.swiper.isBeginning && this.swiper.isEnd
      }
    },
    methods: {
      ...mapActions('basket', ['addProductToBasket']),
      createSwiper() {
        this.swiper = new Swiper(this.$refs['carousel'], {
          wrapperClass: 'popular-block__list',
          slideClass: 'popular-block__item',
          slidesPerView: 'auto',
          watchOverflow: true,
          roundLengths: true,
          observer: true,
          speed: 600,
          threshold: 2,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false
          },
          navigation: {
            prevEl: this.$refs['slide-prev'],
            nextEl: this.$refs['slide-next'],
            disabledClass: '_disabled',
            hiddenClass: '_hidden',
            lockClass: '_hidden',
          },
          pagination: {
            el: this.$refs.pagination,
            type: 'fraction',
          },
          scrollbar: {
            el: this.$refs.scrollbar,
            draggable: true,
          },
          init: false
        })

        this.swiper.on('afterInit', () => {
          this.swiper.el.addEventListener('mouseenter', this.swiper.autoplay.stop)
          this.swiper.el.addEventListener('mouseleave', this.swiper.autoplay.start)
        })
        this.swiper.init()
      }
    },
    mounted() {
      this.createSwiper()
    },
    beforeDestroy() {
      if (this.swiper) {
        this.swiper.el.removeEventListener('mouseenter', this.swiper.autoplay.stop)
        this.swiper.el.removeEventListener('mouseleave', this.swiper.autoplay.start)
        setTimeout(() => {
          this.swiper.destroy()
        }, 1000)
      }
    }
  }
</script>

<style>
</style>
