<template>
  <div class="add-to-basket">
    +{{ quantity }}
  </div>
</template>
<script>
  export  default {
    props: {
      quantity: {
        type: [String, Number],
        default: 1
      }
    }
  }
</script>
