export const addToBasketAnimation = ({target}, el, cb = null) => {
  const time = 700
  const counter = el || document.querySelector('.add-to-basket')
  const counterClone = counter.cloneNode(true)
  // counter.parentElement.appendChild(counterClone)
  document.body.appendChild(counterClone)
  const element = target.getBoundingClientRect()
  const position = {
    x: element.left + element.width / 2 - counter.clientWidth / 2 ,
    y: element.top + element.height / 2 - counter.clientHeight / 2
  }

  const basketIcon = document.querySelector('.page-header__link._basket .page-header__link-label').getBoundingClientRect();
  const iconPosition = {
    x: basketIcon.left,
    y: basketIcon.top
  }

  setStyles(counterClone, {
    left: position.x + 'px',
    top: position.y + 'px',
    transition: `left ${time}ms, top ${time}ms`,
    animationDuration: time + 'ms',
    opacity: 1
  })

  setTimeout(() => {
    setStyles(counterClone,  {
      left: iconPosition.x + 'px',
      top: iconPosition.y + 'px'
    })
    counterClone.classList.add('_animate')
  })

  setTimeout(() => {
    if(cb) {
      cb()
    }
    counterClone.classList.remove('_animate')
    counterClone.classList.remove('_animate')
    counterClone.parentElement.removeChild(counterClone)
  }, time)
}

function setStyles(el, obj) {
  for (const key in obj) {
    el.style[key] = obj[key]
  }
}

