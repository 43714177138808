import {get} from 'lodash'

export default function (category, selectedTime = null) {
  if (!category) return 0
  let from = get(category, 'discount_from', null)
  let to = get(category, 'discount_to', null)
  let size = get(category, 'discount_size', null)
  let discount_days = get(category, 'discount_days', [])

  if (!size || !from || !to) {
    return 0
  }

  let dateFrom = new Date(`0000-01-01T${from}:00`)
  let dateTo = new Date(`0000-01-01T${to}:00`)
  let dateNow = selectedTime ? new Date(selectedTime) : new Date()
  let day = dateNow.getDay()
  if (!discount_days.some(item => item === day)) return 0
  dateNow.setFullYear(0)
  dateNow.setMonth(0)
  dateNow.setDate(1)

  if (dateNow < dateFrom || dateNow > dateTo) {
    return 0
  }
  return size
}