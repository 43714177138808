<template>
  <div class="quantity">
    <button type="button" class="quantity__btn base-btn _minus"
            :class="value === min ? '_disabled' : null"
            @click.prevent="onMinus"
    >
      <BaseSvg name="ico-minus"/>
    </button>
    <input type="number"
           :value="value"
           @input="onInput"
           @blur="onBlur"
           ref="input"
           class="quantity__input">
    <button type="button" class="quantity__btn base-btn _plus"
            :class="value === max ? '_disabled' : null"
            @click.prevent="onPlus"
    >
      <BaseSvg name="ico-plus"/>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'Quantity',
    props: {
      value: {
        type: Number,
        default: 1
      },
      min: {
        type: Number,
        default: 1
      },
      max: {
        type: Number,
        default: 1000
      }
    },
    methods: {
      onMinus() {
        if(this.value <= this.min) return

        this.$emit('input', this.check(this.value - 1))
      },
      onPlus() {
        if(this.value >= this.max) return

        this.$emit('input', this.check(this.value + 1))
      },
      onInput(event) {
        this.$emit('input', this.check(event.target.value))
      },
      onBlur() {
        this.$refs['input'].value = this.value
      },
      check(value) {
        value = parseFloat(value) || this.min

        if (value < this.min) value = this.min
        if (value > this.max) value = this.max

        return value
      }
    }
  }
</script>

<style>

</style>
